import React from 'react'
import Img from "gatsby-image"

const ImageText = ({ image, title, text }) => (
  <div className="columns">
    <div className="column is-4 has-text-centered">
      <Img fluid={image.childImageSharp.fluid} alt={title} />
    </div>
    <div className="column is-8 has-text-centered">
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  </div>
)

export default ImageText
