import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Content, { HTMLContent } from '../components/Content'
import ImageText from '../components/ImageText'

import Layout from '../components/Layout'

export const HistoireDeNaissenceTemplate = ({
  content,
  contentComponent,
  title,
  image,
  description,
  html,
  imageText,
  endingText,
  helmet,
}) => {
  const PostContent = contentComponent || Content
  return(
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p>{description}</p>
            <PostContent content={content} />
            <p>{html}</p>
            {imageText.map(data => <ImageText {...data} />)}
          </div>
        </div>
      </div>
    </section>
  )
}

HistoireDeNaissenceTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

const HistoireDeNaissence = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <HistoireDeNaissenceTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={<Helmet title={`${post.frontmatter.title} | Blog`} />}
        {...post.frontmatter}
      />
    </Layout>
  )
}

HistoireDeNaissence.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default HistoireDeNaissence

export const pageQuery = graphql`
  query HistoireDeNaissence($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        description
        imageText {
          image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          title
          text
        }
        endingText
      }
    }
  }
`
